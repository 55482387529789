import React from "react";
import { graphql } from "gatsby";
import LibraryItem from "../components/LibraryItem/LibraryItem";
import GenericContentIndexPage from "./generic/genericContentIndexTemplate";
import { transformEdgesNodes } from "../components/utils";
import withLayoutAndData from "./generic/withLayoutAndData";

export const LibraryIndexPage = ({ page, preview }) =>
	<GenericContentIndexPage page={page}
	                         ContentItemComponent={LibraryItem}
	                         showAd
	                         preview={preview}/>;

export const pageQuery = graphql`
    query LibraryIndexPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "library-index-page" } }) {
            html
            frontmatter {
                title
                heading
                eventText
            }
            fields {
                slug
            }
            ...RemoteBannerImage
        }
        items: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "library-page" } } }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 400)
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "MMMM DD, YYYY")
                        liveDate(formatString: "MMMM DD, YYYY")
                        featuredpost
                        description
                        showNew
                    }
                    ...RemoteBannerImageSmall
                }
            }
        }
        highlightedEvents: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "event-page"}, featuredpost: {eq: true}}}, limit: 2) {
            edges {
                ...EventHighlight
            }
        }
    }`;

export const postTransform = (node) => ({
	id: node.id,
	excerpt: node.excerpt,
});

export default withLayoutAndData(
	({ data }) => ({
		page: {
			...data.markdownRemark.frontmatter,
			...data.markdownRemark.fields,
			content: data.markdownRemark.html,
			items: transformEdgesNodes(data.items, postTransform),
			highlightedEvents: transformEdgesNodes(data.highlightedEvents, postTransform),
		}
	})
)(LibraryIndexPage);
